import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-28bc397f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "formRef",
      model: _ctx.form,
      rules: _ctx.rules,
      "label-width": "100px"
    }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_el_form_item, {
          label: _ctx.$t('message.form.flag'),
          prop: "flag"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: _ctx.form.flag,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.flag) = $event)),
              placeholder: _ctx.$t('message.form.flag_placeholder'),
              style: {"width":"100%"},
              disabled: _ctx.disabled,
              onChange: _cache[1] || (_cache[1] = ((val) => {_ctx.utilInst.msgTypeChangeHandler(val, _ctx.utilInst)}))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect('flag'), (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.value,
                    label: item.label,
                    value: item.value
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "placeholder", "disabled"])
          ]),
          _: 1
        }, 8, ["label"]), [
          [_vShow, _ctx.modelFrom=='messagePublish']
        ]),
        (_ctx.form.flag==1)
          ? (_openBlock(), _createBlock(_component_el_form_item, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "success",
                  plain: "",
                  style: {"width":"20%"},
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.utilInst.preViewMsg()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('message.form.view_msg')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_alert, {
                  title: _ctx.$t('message.form.view_msg_tip'),
                  type: "info"
                }, null, 8, ["title"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_el_form_item, {
          label: _ctx.$t('message.form.type'),
          prop: "type"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: _ctx.form.type,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.type) = $event)),
              clearable: "",
              placeholder: _ctx.$t('message.form.type_placeholder'),
              style: {"width":"100%"},
              disabled: _ctx.disabled
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect('type'), (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.value,
                    label: item.label,
                    value: item.value
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "placeholder", "disabled"])
          ]),
          _: 1
        }, 8, ["label"]), [
          [_vShow, _ctx.form.flag==0 && _ctx.modelFrom=='messagePublish']
        ]),
        _withDirectives(_createVNode(_component_el_form_item, {
          label: _ctx.$t('message.form.recCorpIds')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_cascader, {
              placeholder: _ctx.$t('message.form.recCorpIds_placeholder'),
              modelValue: _ctx.form.recCorpIds,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.recCorpIds) = $event)),
              props: {multiple: true,checkStrictly: true,emitPath:false},
              style: {"width":"100%"},
              options: _ctx.corpTreeData,
              disabled: _ctx.disabled
            }, null, 8, ["placeholder", "modelValue", "options", "disabled"])
          ]),
          _: 1
        }, 8, ["label"]), [
          [_vShow, _ctx.form.flag==0 && _ctx.form.type==2]
        ]),
        _withDirectives(_createVNode(_component_el_form_item, {
          label: _ctx.$t('message.form.recRoleIds')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: _ctx.form.recRoleIds,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.recRoleIds) = $event)),
              multiple: "",
              placeholder: _ctx.$t('message.form.recRoleIds_placeholder'),
              style: {"width":"100%"},
              disabled: _ctx.selDisableFlag
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roleData, (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.value,
                    label: item.label,
                    value: item.value
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "placeholder", "disabled"])
          ]),
          _: 1
        }, 8, ["label"]), [
          [_vShow, _ctx.form.flag==0 && _ctx.form.type==3]
        ]),
        _withDirectives(_createVNode(_component_el_form_item, {
          label: _ctx.$t('message.form.recUserIds')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              ref: "userSelRef",
              modelValue: _ctx.form.recUserIds,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.recUserIds) = $event)),
              multiple: "",
              placeholder: _ctx.$t('message.form.recUserIds_placeholder'),
              style: {"width":"100%"},
              disabled: _ctx.selDisableFlag
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userData, (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.value,
                    label: item.label,
                    value: item.value
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "placeholder", "disabled"])
          ]),
          _: 1
        }, 8, ["label"]), [
          [_vShow, _ctx.form.flag==1 || _ctx.form.type==4]
        ]),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('message.form.title'),
          prop: "title"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              onInput: _cache[7] || (_cache[7] = e => _ctx.form.title = _ctx.valid(e)),
              modelValue: _ctx.form.title,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.title) = $event)),
              placeholder: _ctx.$t('message.form.title_placeholder'),
              maxlength: "200",
              disabled: _ctx.disabled,
              clearable: ""
            }, null, 8, ["modelValue", "placeholder", "disabled"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('message.form.content'),
          prop: "content"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              ref: el=>_ctx.refMap.set('msgContent',el)
            }, null, 512)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('message.form.publishTime'),
          prop: "publishTime"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_date_picker, {
              modelValue: _ctx.form.publishTime,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.publishTime) = $event)),
              disabled: true
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('message.form.userId'),
          prop: "userId"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.form.userId,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.userId) = $event)),
              maxlength: "20",
              disabled: true
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}